<template>
<div class="page1">
  <!--主体内容-->
  <div class="content">
    <TitleModule :title="'添加/修改角色信息'"></TitleModule>
    <!--搜索条件区域-->
    <div class="searchWrapper">
      <el-form label-position="right" label-width="100px" :model="formInline" style="width: 440px;" :rules="rules" ref="form">
        <el-form-item :label="$t('searchModule.Role_Name')" prop="monitorManagerRoleName">
          <el-input :maxlength="20" v-model.trim="formInline.monitorManagerRoleName" placeholder="角色名称"></el-input>
        </el-form-item>
        <!-- <el-form-item label="所属平台" prop="systemType">
						<el-select v-model.trim="formInline.systemType" filterable placeholder="所属平台">
							<el-option label="监管平台" :value="3"></el-option>
							<el-option label="运维平台" :value="2"></el-option>
						</el-select>
					</el-form-item>					 -->
        <el-form-item :label="$t('searchModule.remarks')">
          <el-input type="textarea" :maxlength="20" v-model.trim="formInline.description" placeholder="备注"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin-top: 20px; ">
        <el-button type="primary" @click="submitData" style="width: 88px;">提交</el-button>
        <el-button type="primary" @click="setResourse" style="width: 94px;">配置权限</el-button>
        <el-button type="" @click="$router.push('/customerRole')" style="width: 88px;">取消</el-button>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import TitleModule from '@/components/titleModule/titleModule'
export default {
  name: 'hello',
  data() {
    return {
      isEdit: false,
      treeList: [],
      oldName: '',
      resourceList: [],
      defaultProps: {
        children: 'children',
        label: 'resourceName'
      },
      formInline: {
        monitorManagerRoleName: '',
        resourceIds: '',
        description: ''
      },
      rules: {
        monitorManagerRoleName: [{
          required: true,
          message: '请输入角色名称',
          trigger: 'blur'
					}]
      }
    }
  },
  methods: {
    queryDetail() {
      let data = this.$route.query
      for (let i in this.formInline) {
        this.formInline[i] = data[i]
      }
    },
    submitData() {
      if (!this.formInline.description) {
        this.formInline.description = '';
      }
      let url = !this.isEdit ? '/acb/2.0/monitorManagerRole/create' : '/acb/2.0/monitorManagerRole/update'
      let method = 'post'
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.formInline.resourceIds) {
            this.$alert('没有配置权限', this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
            return
          }
          this.$axios[method](url, {
            data: this.formInline
          }).then(res => {
            if (res.state == 0) {
              this.$message({
                type: 'info',
                message: '操作成功'
              });
              sessionStorage.resourceIds = '';
              sessionStorage.juese = '';
              this.$router.push('/customerRole');
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine')
              });
            }
          })
        } else {
          return false;
        }
      });
    },
    setResourse() {
      sessionStorage.juese = JSON.stringify(this.formInline);
      this.$router.push({
        path: '/configPermiss',
        query: {
          resourceIds: this.formInline.resourceIds
        }
      })
    }
  },
  components: {
    TitleModule
  },
  created() {
    if (sessionStorage.juese) {
      this.formInline = JSON.parse(sessionStorage.juese)
      sessionStorage.juese = ''
    }
  },
  mounted() {
    if (this.$route.query.monitorManagerRoleId) {
      this.isEdit = true;
      this.formInline.monitorManagerRoleId = this.$route.query.monitorManagerRoleId;
      this.queryDetail();
    }
    if (sessionStorage.resourceIds) {
      this.formInline.resourceIds = sessionStorage.resourceIds;
      sessionStorage.resourceIds = ''
    }
  },
  computed: {}
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
	height: 35px;
.content
	background: #FFFFFF;
	overflow: hidden;
	border: 1px solid #C0CCDA;
	border-radius: 4px;
	padding: 20px;
</style>
