var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("TitleModule", { attrs: { title: "添加/修改角色信息" } }),
        _c(
          "div",
          { staticClass: "searchWrapper" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                staticStyle: { width: "440px" },
                attrs: {
                  "label-position": "right",
                  "label-width": "100px",
                  model: _vm.formInline,
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("searchModule.Role_Name"),
                      prop: "monitorManagerRoleName",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { maxlength: 20, placeholder: "角色名称" },
                      model: {
                        value: _vm.formInline.monitorManagerRoleName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "monitorManagerRoleName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.monitorManagerRoleName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("searchModule.remarks") } },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        maxlength: 20,
                        placeholder: "备注",
                      },
                      model: {
                        value: _vm.formInline.description,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "description",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.description",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "text-align": "center", "margin-top": "20px" } },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "88px" },
                    attrs: { type: "primary" },
                    on: { click: _vm.submitData },
                  },
                  [_vm._v("提交")]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "94px" },
                    attrs: { type: "primary" },
                    on: { click: _vm.setResourse },
                  },
                  [_vm._v("配置权限")]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "88px" },
                    attrs: { type: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push("/customerRole")
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }